import 'whatwg-fetch';

import Main from 'view-controllers/main';
import 'styles/styles.css';

const divs = document.querySelectorAll('#rk-top-tracks-widget');
const script = document.querySelector('#rk-top-tracks-script');

if (divs.length) {
  divs.forEach(div => {
    const initialized = div.getAttribute('data-initialized');

    if (initialized === null) {
      div.setAttribute('data-initialized', '');
      const slug = div.getAttribute('data-id') || '0';
      const buyButton = div.getAttribute('data-buy') || '1';
      const count = div.getAttribute('data-count') || '5';
      const main: Main = new Main(slug, buyButton, count);
      div.appendChild(main.$element);
    }
  });
} else if (script && script.parentElement) {
  const scriptDiv = document.createElement('div');
  scriptDiv.innerHTML = '<div id="rk-top-tracks-app"></div>';
  script.parentElement.insertBefore(scriptDiv, script);
  const slug = script.getAttribute('data-id') || '0';
  const buyButton = script.getAttribute('data-buy') || '1';
  const count = script.getAttribute('data-count') || '5';
  const main: Main = new Main(slug, buyButton, count);
  const app: HTMLElement | null = document.querySelector('#rk-top-tracks-app');
  if (app) {
    app.appendChild(main.$element);
  }
}
