import Component from 'lib/component';
import ErrorAlert from 'components/error-alert';
import style from './main.css';
import TrackService, { Track } from '../services/track';
import TopTracks from '../components/top-tracks';
import Loader from '../components/loader';

export default class Main extends Component {
  public errorAlert = new ErrorAlert();
  public content = new Component();
  public loader = new Loader();
  private displayBuyLink = true;
  private count = 5;

  public constructor(slug: string, buy: string, count: string) {
    super({ className: style.mainView });

    if (!slug) {
      this.setupErrorView();
    } else {
      this.count = Number.parseInt(count, 10);
      this.displayBuyLink = buy !== '0';
      this.fetchTopTracks(slug);
      this.setupViews();
    }
  }

  private setupViews(): void {
    this.errorAlert.hide();
    this.add(this.loader);
    this.add(this.errorAlert);
    this.add(this.content);
  }

  private setupErrorView(): void {
    this.loader.hide();
    this.errorAlert.show();
    this.add(this.errorAlert);
  }

  private fetchTopTracks(id: string): void {
    const trackService = new TrackService();
    const rez = trackService.fetchTopTracks(id, this.count);
    rez.subscribe(this.displayCurrentTrack.bind(this), () =>
      this.setupErrorView(),
    );
  }

  private displayCurrentTrack(result: Track[]): void {
    this.loader.hide();
    this.content.add(new TopTracks(result, this.displayBuyLink));
  }
}
