import { fromFetch } from 'rxjs/fetch';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { getJson } from '../lib/operators';
import config from '../config';

export interface ApiTrack {
  title: string;
  artist: string;
  cover_url: string;
  started_at: string;
  buy_link: string;
}

export interface Track {
  title: string;
  artist: string;
  cover: string;
  startedAt: string;
  buyLink: string;
}

export default class TrackService {
  public url: string;
  public constructor() {
    const { widget } = config.rkApi;
    this.url = `${widget}/radio/`;
  }

  public fetchTopTracks = (
    slug: string,
    tracks: number,
  ): Observable<Track[]> => {
    const url = `${this.url}${slug}/track/top?limit=${tracks}`;
    return fromFetch(url).pipe(
      getJson<ApiTrack[]>(),
      map(data => data.map(track => TrackService.convertToTrack(track))),
      catchError(v => throwError(v)),
    );
  };

  static convertToTrack(data: ApiTrack): Track {
    return {
      title: data.title,
      artist: data.artist,
      cover: data.cover_url,
      startedAt: data.started_at,
      buyLink: data.buy_link,
    };
  }
}
